import React, { useState } from 'react'
import { Select as BaseSelect, selectClasses } from '@mui/base/Select';
import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import { Popper as BasePopper } from '@mui/base/Popper';
import { styled, Box } from '@mui/system';
import { func } from '../../../Utilities/logFunc';

const GameDropDown = ({options,name,handleChnage}) => {
  func("countryList 8 ", options)
    const [optionValue, setOptionValue] = useState(options[0]?.label)
  return (
    <div>
      
        <Select 
        value={optionValue}
        
        id="named-select" 
        name={name} 
        // onChange={(e)=>{
        //     func("sadfgh",e.current)
        //     setOptionValue(e?.current)
        //     }}
        // value={optionValue?.dialingCode}
        onChange={(_, newValue) => {
            func("sadfgh",newValue)
            handleChnage(newValue)
        }}
            
            >

            {
        options?.map((item,ind)=>(
            
          <Option 
         
        
            value={item?._id} 
           
            >
            {item?.name}
            </Option>
                    
                ))
            }
         
        </Select>
    </div>
  )
}

const Select = React.forwardRef(function CustomSelect(props, ref) {
    const slots = {
      root: Button,
      listbox: Listbox,
      popper: Popper,
      ...props.slots,
    };
  func("cuure", ref)
    return <BaseSelect {...props} ref={ref} slots={slots} />;
  });
  

  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };
  
  const Button = styled('button')(
    ({ theme }) => `
   height:3.75rem;
    width:100%;
    font-size: 0.875rem;
    box-sizing: border-box;
   
    padding: 10px 15px;
    border-radius: 50px;
    text-align: left;
    line-height: 1.5;
    background: var(--secondary-color);
   border:none;
    color: var(--color-white);
  
  
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
   
  
    &.${selectClasses.focusVisible} {
     
      outline: 3px solid blue;
    }
  
    &.${selectClasses.expanded} {
      &::after {
        content: '▴';
      }
    }
  
    &::after {
      content: '▾';
      float: right;
    }
    `,
  );
  
  const Listbox = styled('ul')(
    ({ theme }) => `
   
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 10px;
    margin: 12px 0;
    min-width: 320px;
    border-radius: 20px;
    overflow-y: auto;
    outline: 0px;
    background: var(--secondary-color);
    min-height:54px;
    max-height:300px;
    color: white;
    
    `,
  );
  
  const Option = styled(BaseOption)(
    ({ theme }) => `
    list-style: none;
    padding: 12px;
    border-radius: 15px;
    cursor: default;
    margin-bottom:5px;
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${optionClasses.selected} {
   
      color: var(--color-white);

    }
  
    &.${optionClasses.highlighted} {
      background-color: var(--primary-color-emphasis);
      color: var(--color-white);

    }
  
    &.${optionClasses.highlighted}.${optionClasses.selected} {
      background-color: var(--secondary-color);
      color: var(--color-white);
    }
  
    &.${optionClasses.disabled} {
        color: var(--color-white);  
    }
  
    &:hover:not(.${optionClasses.disabled}) {
      background-color: var(--primary-color-emphasis);
      color: var(--color-white);
    }
    `,
  );
  
  const Popper = styled(BasePopper)`
    z-index: 1;
  `;
  
  const Label = styled('label')(
    ({ theme }) => `
   
    font-size: 0.85rem;
    display: block;
    margin-bottom: 4px;
    font-weight: 400;
    color: ${theme.palette.mode === 'dark' ? grey[400] : grey[700]};
    `,
  );

export default GameDropDown
